<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body lang-name">{{ $t(currentLocale.name) }}</span>
    </template>

    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLang(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { EventBus } from '@/@core/mixins/eventBus'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },

  methods: {
    setValidatorLocalize(locale) {
      localize(locale)
    },

    changeLang(localeObj) {
      this.$i18n.locale = localeObj.locale
      this.setValidatorLocalize(localeObj.locale)
      localStorage.localeObj = this.$i18n.locale
      EventBus.$emit("localeObj", this.$i18n.locale);
    },
  },

  mounted() {
    if (localStorage.localeObj != null) this.$i18n.locale = localStorage.localeObj
    this.setValidatorLocalize(this.$i18n.locale)
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/uk.png'),
        name: 'English',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'German',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>
@media screen and (max-width: 560px){
  .lang-name{
    display: none;
  }
}

</style>
